import React from "react"
import { Paper, Grid, Typography, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import Image from "gatsby-image"
import { navigate, graphql, useStaticQuery } from "gatsby"
import TopicCard from "../topics/topicCard"

const useStyles = makeStyles(theme => ({
  container: {
    margin: "20px 0px 20px 0px",
    padding: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  image: {
    borderRadius: "5px",
    width: "80px",
    height: "80px",
    marginRight: "20px",
    opacity: 0.9,
  },
  gridContainer: {
    margin: "10px 0px",
    paddingRight: "20px",
  },
  button: {
    marginLeft: "15px",
  },
}))

const queryArticles = graphql`
  query landingArticles {
    allStrapiTopic {
      nodes {
        category {
          slug
        }
        slug
        title
        subtitle
        coverImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const KnowledgeTile = ({ image, ckdStage }) => {
  const classes = useStyles()

  const {
    allStrapiTopic: { nodes: allArticles },
  } = useStaticQuery(queryArticles)

  const getArticles = ckdStage => {
    let articles = []
    if (ckdStage === "Dialysis") {
      articles = ["volume-control", "dialysis-lab", "acid-base-balance"]
    } else {
      articles = ["lifestyle-changes", "slow-down-disease-progression", "alkaline-diet"]
    }
    const selectedArticles = allArticles.filter(a => articles.includes(a.slug))
    return selectedArticles
  }

  return (
    <Paper elevation={1} className={classes.container}>
      <div className={classes.row}>
        <Image className={classes.image} fluid={image} />
        <div>
          <Typography variant="h4">Kidney Knowledge</Typography>
          <Typography variant="h6" paragraph>
            Enable yourself
          </Typography>
        </div>
      </div>
      <Typography component="ul">
            <li>
              Know the facts that help you to lead a healthier and longer life
            </li>
            <li>Learn how to interprete and improve your lab values</li>
          </Typography>
      <Grid container spacing={3} className={classes.gridContainer}>
        {getArticles(ckdStage).map(article => {
          return (
            <Grid
              item
              xs={12}
              md={4}
              className={classes.card}
              key={article.slug}
            >
              <TopicCard small category={article.category} topic={article} />
            </Grid>
          )
        })}
      </Grid>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => navigate("/knowledge")}
      >
        View all Articles
      </Button>
    </Paper>
  )
}

export default KnowledgeTile
