import React, { useState, useContext } from "react"
import { AppContext } from "../context/appContext"
import { FoodCheckContext } from "../context/foodCheckContext"
import initialNutrientSelection from "./tools/tool/nutritionTools/nutrientTable/nutrientsStandard"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogActions,
  Button,
  Divider,
} from "@material-ui/core"
import { HealthCheckContext } from "../context/healthCheckContext"
import Image from "gatsby-image"
import Privacy from "./about/privacy"
import Terms from "./about/terms"
import { makeStyles } from "@material-ui/styles"
import { ScreenContext } from "../context/screenContext"

const useStyles = makeStyles({
  termsBlock: {
    marginTop: "25px",
  },
  divider: {
    margin: "15px 0px"
  }
})

const StartDialog = ({ open, setOpen }) => {
  const { CKDStage, setCKDStage, allStages, setConditionsAccept } = useContext(
    AppContext
  )
  const { mobile } = useContext(ScreenContext)
  const { setStatus } = useContext(HealthCheckContext)
  const foodCheckTool = useContext(FoodCheckContext)

  const [selectedStage, setSelectedStage] = useState(
    CKDStage ? CKDStage : "Dialysis"
  )

  const classes = useStyles()

  const onAccept = () => {
    setCKDStage(selectedStage)
    foodCheckTool.setSelectedNutrients(initialNutrientSelection(selectedStage, mobile))
    setStatus("start")
    setConditionsAccept(true)
    setOpen(false)
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={mobile ? true : false}
      scroll="paper"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Kidney Disease Stage & Terms of Use</DialogTitle>

      <DialogContent dividers>
        <List >
          {allStages.map((stage, index) => {
            return (
              <ListItem
                alignItems="flex-start"
                disableRipple
                button
                key={index}
                selected={stage.stageCKD === selectedStage}
                onClick={() => {
                  setSelectedStage(stage.stageCKD)
                }}
              >
                <ListItemAvatar
                  style={{
                    height: "70px",
                    width: "90px",
                  }}
                >
                  <Image fluid={stage.image.childImageSharp.fluid} />
                </ListItemAvatar>
                <ListItemText
                  style={{ marginLeft: "15px" }}
                  primary={stage.stageCKD}
                  secondary={stage.descriptionCKD}
                />
              </ListItem>
            )
          })}
        </List>

        <Divider className={classes.divider}/>

        <div className={classes.termsBlock}>
          <Privacy dialog />
        </div>
        <div className={classes.termsBlock}>
          <Terms dialog />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onAccept()} color="primary" variant="contained">
          Accept & Start
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default StartDialog
