import React, { useContext, useState } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/styles"
import { ScreenContext } from "../context/screenContext"
import { AppContext } from "../context/appContext"
import {
  Container,
  Grid,
  Typography,
  FormLabel,
  FormGroup,
  Paper,
  Button,
} from "@material-ui/core"
import KnowledgeTile from "../components/landing/knowledge"
import Image from "gatsby-image"
import StartDialog from "../components/startDialog"
import StageSelector from "../components/stageSelector";
import FoodCheck from "../components/tools/tool/nutritionTools/foodCheck";
import HealthCheckStart from "../components/tools/tool/labTools/healthCheckStart";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: "1800px",
    margin: "15px auto 40px auto",
  },
  containerMobile: {
    marginTop: "10px",
    paddingBottom: "100px"
  },
  introContainer: {
    marginTop: "5px",
  },
  introText: {
    color: "rgb(80, 80, 80)",
    marginBottom: "10px",
  },
  introImage: {
    opacity: "0.9",
    borderRadius: "15px",
    marginBottom: "10px"
  },
  ckdFormGroup: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  ckdLabel: {
    marginRight: "10px",
    size: "1.1em",
  },
  tileContainer: {
    padding: "20px 30px",
    margin: "20px 0px 20px 0px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px"
  },
  image: {
    borderRadius: "5px",
    width: "80px",
    height: "80px",
    marginRight: "20px",
    opacity: 0.9,
  },
  button: {
    marginTop: "20px",
  },
}))

const queryImages = graphql`
  query landingImages {
    allFile(
      filter: {
        relativePath: {
          in: ["knowledge.jpeg", "nutritionTools.jpeg", "kidney_landing.jpg", "tools.jpg"]
        }
      }
    ) {
      nodes {
        relativePath
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

const IndexPage = () => {
  const screen = useContext(ScreenContext)
  const { CKDStage } = useContext(AppContext)

  const [openStageDialog, setOpenStageDialog] = useState(false)

  const classes = useStyles()

  const {
    allFile: { nodes: images },
  } = useStaticQuery(queryImages)

  const getImage = relPath => {
    return images.filter(img => img.relativePath === relPath)[0].childImageSharp
      .fluid
  }

  return (
    <Layout activePage="home">
      <SEO title="Home" />
      <Container
        className={screen.mobile ? classes.containerMobile : classes.container}
      >
        {!CKDStage ? (
          <StartDialog open={true} setOpen={() => null} />
        ) : (
          <>
            <Grid
              container
              spacing={screen.mobile ? 0 : 5}
              className={classes.introContainer}
            >
              <Grid item xs={12} md={3}>
                <Image
                  fluid={getImage("kidney_landing.jpg")}
                  className={classes.introImage}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <div>
                  <Typography variant="h6" className={classes.introText}>
                    <strong>Kidney24</strong> is an Independent Platform to help people with
                    Chronic Kidney Disease. <br /> We believe in the Power of{" "}
                    Healthy Food and Lifestyle.
                  </Typography>
                </div>

                <FormGroup row className={classes.ckdFormGroup}>
                  <FormLabel component="legend" className={classes.ckdLabel}>
                    Kidney Disease Stage:
                  </FormLabel>
                  <StageSelector/>
                </FormGroup>
              </Grid>
            </Grid>

            <KnowledgeTile
              image={getImage("knowledge.jpeg")}
              ckdStage={CKDStage}
            />

            <Paper className={classes.tileContainer}>
              <div className={classes.row}>
                <Image className={classes.image} fluid={getImage("nutritionTools.jpeg")} />
                <div>
                  <Typography variant="h4">Nutrition Tool</Typography>
                  <Typography variant="h6" paragraph>
                    Explore Nutrient Composition of Foods and Optimize your Diet
                  </Typography>
                </div>
              </div>
              <FoodCheck compact/>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => navigate("/nutrients")}
              >
                Explore Nutrients
              </Button>
            </Paper>

            <Paper className={classes.tileContainer}>
              <div className={classes.row}>
                <Image className={classes.image} fluid={getImage("tools.jpg")} />
                <div>
                  <Typography variant="h4">Health Advisor</Typography>
                  <Typography variant="h6" paragraph>
                    Assess your Health Status
                  </Typography>
                </div>
              </div>
              <HealthCheckStart/>

            </Paper>
          </>
        )}
      </Container>
    </Layout>
  )
}

export default IndexPage
